<!-- 超出围栏预警详情 -->
<template>
    <section v-if="current?.businessType == 9">
        <a-descriptions title="" layout="vertical" bordered :column="2" size="small" class="descript"
            style="border-top: 1px solid #f0f0f0">
            <a-descriptions-item label="印章名称">{{ current?.sealName || '-' }}</a-descriptions-item>
            <a-descriptions-item label="印章保管员">{{ current?.custodianName || '-' }}</a-descriptions-item>
            <a-descriptions-item label="预警类型">{{
                warningStatusObj[current?.businessType].name || '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="预警信息">{{ current?.content || '-' }}</a-descriptions-item>
            <a-descriptions-item label="预警时间">{{ current?.warningTime || '-' }}</a-descriptions-item>
            <a-descriptions-item label="位置更新时间">{{ current?.uploadTime || '-' }}</a-descriptions-item>
            <a-descriptions-item label="印章位置">{{ current?.sealAddress || '-' }}</a-descriptions-item>
        </a-descriptions>
    </section>
</template>
<script setup>
import { defineProps } from 'vue'
import { warningStatusObj } from '@/utils/constData'

const props = defineProps({
    current: {
        type: Object,
        default: () => ({})
    }
})
</script>
<style lang="less" scoped>
@import url('./shareStyle.less');
</style>
